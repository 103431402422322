exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-colour-tsx": () => import("./../../../src/pages/colour.tsx" /* webpackChunkName: "component---src-pages-colour-tsx" */),
  "component---src-pages-design-principles-tsx": () => import("./../../../src/pages/design-principles.tsx" /* webpackChunkName: "component---src-pages-design-principles-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-logo-tsx": () => import("./../../../src/pages/logo.tsx" /* webpackChunkName: "component---src-pages-logo-tsx" */),
  "component---src-pages-mixed-media-tsx": () => import("./../../../src/pages/mixed-media.tsx" /* webpackChunkName: "component---src-pages-mixed-media-tsx" */),
  "component---src-pages-photography-tsx": () => import("./../../../src/pages/photography.tsx" /* webpackChunkName: "component---src-pages-photography-tsx" */),
  "component---src-pages-tone-of-voice-tsx": () => import("./../../../src/pages/tone-of-voice.tsx" /* webpackChunkName: "component---src-pages-tone-of-voice-tsx" */),
  "component---src-pages-typography-tsx": () => import("./../../../src/pages/typography.tsx" /* webpackChunkName: "component---src-pages-typography-tsx" */)
}

