import { ThemeUIStyleObject } from 'theme-ui'

const breakpoints = [576, 768, 992, 1200]

const heading = {
  fontFamily: 'body',
  fontWeight: 'heading',
  lineHeight: 'heading',
  letterSpacing: -0.38,
}

const bodyText = {
  fontFamily: 'body',
  fontWeight: 'body',
  lineHeight: 'body',
  letterSpacing: -0.18,
}

const white = '#fff'
const black = '#373E4D'
const coral = '#F38374'
const blue = '#2C5CAA'
const blueSoda = '#B8D1DB'
const redSoda = '#E43C2F'
const goldCandy = '#B3A168'
const premiumBlue = '#002274'
const yellowDip = '#F5EA47'
const greenDip = '#00607F'
const pinkCandy = '#F5B5C3'
const blueLight = '#F1F5F8'
const bluePremium = '#003399'

type FunctionSxStyleProp = () => ThemeUIStyleObject

const flexCenter: FunctionSxStyleProp = () => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const transformCenter: FunctionSxStyleProp = () => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: `translate(-50%, -50%)`,
})

const theme = {
  useBodyStyles: false, // to prevent default styles being applied globally by theme-ui
  useCustomProperties: false,
  shadows: {
    small: `0 2px 4px rgba(0,0,0, 0.12)`,
    large: `0 12px 24px rgba(21,45,64,0.1)`,
  },
  colors: {
    black,
    blue,
    coral,
    white,
    goldCandy,
    background: white,
    text: black,
    redSoda,
    premiumBlue,
    yellowDip,
    greenDip,
    pinkCandy,
    blueSoda,
    blueLight,
    bluePremium,
  },
  fontSizes: [18, 24, 32, 48, 60],
  sizes: {
    navDesktop: 72,
    navMobile: 50,
    mobileContainerGutter: 30,
  },
  heights: {
    nav: `72px`,
    navMobile: '50px',
    heroDesktop: 650,
    heroMobile: 338,
  },
  space: [0, 4, 8, 16, 32, 48, 64, 96, 128, 192, 256],
  zIndices: {
    nav: 10,
  },
  z: {
    nav: 10,
  },
  radii: {
    small: 4,
    mid: 8,
  },
  utility: {
    flexCenter: flexCenter(),
    transformCenter: transformCenter(),
  },
  breakpoints: breakpoints.map((point) => `${point}px`),
  // custom media query
  cmq: (pixels) => `@media (min-width: ${pixels}px)`,
  mq: breakpoints.map((bp) => `@media (min-width: ${bp}px)`),
  cmqxyMax: (pxWidth, pxHeight) =>
    `@media only screen and (max-width: ${pxWidth}px), only screen and (max-height: ${pxHeight}px)`,
  halfBordersCurved: (alt = false) =>
    alt
      ? {
          borderRadius: [`0 32px`, , `0 56px`, `0 64px`],
        }
      : {
          borderRadius: [`32px 0`, , `56px 0`, `64px 0`],
        },
  revealAnim: (condition) => ({
    transform: `translateY(${condition ? 0 : 32}px)`,
    opacity: condition ? 1 : 0,
    transition: `0.8s ease-out all`,
    transitionProperty: 'opacity, transform',
  }),
  fonts: {
    body: 'Basis Grotesque Pro, Barlow',
    numeric: 'Barlow',
  },
  fontWeights: {
    body: 400,
    heading: 900,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.13,
  },
  buttons: {
    primary: {
      bg: 'blue',
      color: 'white',
      '&:hover, &:focus': {
        color: 'white',
      },
    },
    inverted: {
      bg: 'white',
      color: 'blue',
      '&:hover, &:focus': {
        color: 'blue',
      },
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    h1: {
      ...heading,
      fontSize: [24, , 40],
    },
    h2: {
      ...heading,
      fontSize: [24, , 40],
    },
    h3: {
      ...heading,
      fontSize: [16, , 24],
    },
    p: {
      ...bodyText,
      fontSize: [18],
    },
  },
  animations: {
    inView: {
      fadeIn: (delay = 0) => ({
        opacity: 0,
        transition: 'all 0.75s',
        transitionDelay: `${delay}s`,
        '.inView &': {
          opacity: 1,
        },
      }),
      fadeOut: (delay = 0) => ({
        opacity: 1,
        transition: 'all 0.75s',
        transitionDelay: `${delay}s`,
        '.inView &': {
          opacity: 0,
        },
      }),
      fadeInUp: (delay = 0) => ({
        opacity: 0,
        transform: 'translateY(75px)',
        transition: 'all 0.75s',
        transitionDelay: `${delay}s`,
        '.inView &': {
          opacity: 1,
          transform: `translateY(0px)`,
        },
      }),
    },
  },
}

export default theme
